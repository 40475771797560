/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { ReactComponent as LockSvg } from './assets/lock.svg'

const View = ({
  isActive,
  marketList,
  otcList,
  marketPresets,
  otcPresets,
  onClick,
  activeItem,
}) => {

  if (!activeItem) return null;
  const { id: activeId, term: activeTerm } = activeItem;

  const renderSignalTypeList = ({ id, name, unlocked, source }) => (
    <div className="signal_type_item" key={id}>
      {/* <!-- Элемент Signal Type с галочкой --> */}
      {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
      <div className={`signal_type_name_box ${id.toString() === activeId.toString() ? "active" : ""}`}>
        <span />
        <div className="signal_type_name">{name}</div>
      </div>
      {/* <!-- Элемент Signal Type с галочкой конец --> */}
      <div className="signal_type_graph">
        {/* <!-- Цифровые значения Signal Type --> */}
        {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
        {unlocked ? (
          <>
            {[1, 5, 15].map((value) => (
              <div
                className={id.toString() === activeId.toString() && activeTerm === value ? "active" : ""}
                key={value}
                onClick={() => {
                  onClick({ id, term: value, source, name });
                }}
              >
                {value}
              </div>
            ))}
            <div
              className={id.toString() === activeId.toString() && !activeTerm ? "active" : ""}
              onClick={() => {
                onClick({ id, term: null, source, name });
              }}
            >
              All
            </div>
          </>
        ) : (
          <LockedWrapper>
            <LockIcon height={8} width={10} />
            Available for PRO
          </LockedWrapper>
        )}
        {/* <!-- Цифровые значения Signal Type конец --> */}
      </div>
    </div>
  );

  const renderPreset = (preset) => (
    <div className="signal_type_item" key={preset.presetId}>
      {/* <!-- Элемент Signal Type с галочкой --> */}
      {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
      <div
        className={`signal_type_name_box ${
          preset.presetId.toString() === activeId.toString() ? "active" : ""
        }`}
      >
        <span />
        <div className="signal_type_name">{preset.presetName}</div>
      </div>
      {/* <!-- Элемент Signal Type с галочкой конец --> */}
      <div className="signal_type_graph">
        {/* <!-- Цифровые значения Signal Type --> */}
        {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
        {preset.expiration
          .sort((a, b) => a - b)
          .map((value) => (
            <div
              className={
                preset.presetId.toString() === activeId.toString() && activeTerm === value ? "active" : ""
              }
              key={value}
              onClick={() => {
                onClick({ id: preset.presetId, term: value, source: preset.source, name: preset.presetName });
              }}
            >
              {value}
            </div>
          ))}
        <div
          className={preset.presetId.toString() === activeId.toString() && !activeTerm ? "active" : ""}
          onClick={() => {
            onClick({ id: preset.presetId, term: null, source: preset.source, name: preset.presetName });
          }}
        >
          All
        </div>
        {/* <!-- Цифровые значения Signal Type конец --> */}
      </div>
    </div>
  );

  return (
    <>
      {/* <!-- Список тип сигнала --> */}
      {/* <!-- Изначально скрыт, появляется при добавлении класса active --> */}
      <div className={`signal_type ${isActive ? "active" : ""}`}>
        <div className="signal_type_title">Signals type</div>
        <div className="signal_type_wrap">
          {!!otcList?.length && <SignalHeader>Market</SignalHeader>}
          {marketList && marketList.length && marketList.map(renderSignalTypeList)}
          {marketPresets && marketPresets.length && marketPresets.map(renderPreset)}
          {otcList && otcList.length && <SignalHeader>OTC Pocketoption</SignalHeader>}
          {otcList && otcList.length && otcList.map(renderSignalTypeList)}
          {otcPresets && otcPresets.length && otcPresets.map(renderPreset)}
        </div>
      </div>
      {/* <!-- Список тип сигнала конец --> */}
    </>
  );
};

const LockedWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100% !important;
  color: #9d9d9d !important;
`;

const LockIcon = styled(LockSvg)`
  margin-right: 5px;
`;

const SignalHeader = styled.div`
  width: 100%;
  background: #2f3545;
  color: rgba(255, 255, 255, 0.5);
  padding: 7px 10px;
  font-size: 14px;
`;


export default View;