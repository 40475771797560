import { createAction } from "redux-actions";

export const newTab = createAction("TABS_PANEL/NEW_TAB");
export const closeTab = createAction("TABS_PANEL/CLOSE_TAB");
export const setActiveTab = createAction("TABS_PANEL/SET_ACTIVE_TAB");
export const setTabTitle = createAction("TABS_PANEL/SET_TAB_TITLE");

export const initActiveSignalsType = createAction("LEFT_PANEL_SIGNALS_TYPE/INIT");
export const setActiveSignalsType = createAction("LEFT_PANEL_SIGNALS_TYPE/SET_ACTIVE");
export const setActiveBroker = createAction("LEFT_PANEL_BROKER_SETTINGS/SET_ACTIVE");

export const toggleLayoutSettings = createAction("LAYOUT/TOGGLE_SETTINGS");
export const setDashboardScale = createAction("CLIENT/SET_DASHBOARD_SCALE");

export const updateTms = createAction("CLIENT/UPDATE_TMS");
export const updateTmsShift = createAction("CLIENT/UPDATE_TMS_SHIFT");

export const setSignalView = createAction("CLIENT/SET_SIGNAL_VIEW");

export const setShowConnectBrokerPage = createAction("CLIENT/SHOW_START_PAGE");

export const setBrokerIntegrationList = createAction("CLIENT/SET_BROKER_INTEGRATION_LIST");
export const resetBrokerIntegrationList = createAction("CLIENT/RESET_BROKER_INTEGRATION_LIST");
