import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { receiveSignals, receiveInitialSignals } from "./actions";

const mapCallback = (signalData) => {
  const { symbol, timestamp, signal, alg, price, expiration } = signalData;
  return {
    id: `${symbol}:${timestamp}:${signal}:${alg}:${price}:${expiration}`,
    ...signalData,
  };
};

export default combineReducers({
  list: handleActions(
    {
      [receiveSignals]: (state, action) => {
        const { source, signals } = action.payload;
        const list = state.find(({ source }) => source === action.payload.source);

        if (!list) {
          state.push({ source, signals: signals.map(mapCallback).reverse() });
          return [...state];
        }

        list.signals = signals.map(mapCallback).reverse();

        return [...state];
      },
      [receiveInitialSignals]: (state, action) => {
        const { source } = action.payload;
        const signals = action.payload.signals.map(mapCallback);
        return [{ signals: signals.reverse(), source }];
      },
    },
    [],
  ),
});
