import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import View from "./View";
import Signal from "../Signal";
import { getAlgorithms, getFreeAssets, getSignalTypesList } from "../../../../modules/initial";
import {
  getActiveBroker,
  getActiveSignalsType,
  getAvailableBrokerIntegrationList,
  getCurrentTms,
  getSignalView,
  newTab,
  openActiveBrokerTabThunk,
} from "../../../../modules/client";
import { getSignals } from "../../../../modules/signals";
import { isClientPRO } from "../../../../modules/mainSettings";
import { setSymbol, setTerm, setEnabled } from "../../../../modules/dashboard";
import { getFiltersPresets } from "../../../../modules/filtersSettings";
import events from "../../../../packages/eventBus";
import withLogger from "../../../../hoc/withLogger";

class SignalsList extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    signals: PropTypes.array.isRequired,
    freeAssets: PropTypes.array.isRequired,
  };

  state = {
    increment: 0,
  };

  interval;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ increment: this.state.increment + 1 });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleSetSymbolClicked = (term, symbol) => {
    const { setEnabled, setSymbol, setTerm } = this.props;
    setEnabled({ dashboardId: 0, value: true });
    setSymbol({ dashboardId: 0, value: symbol });
    setTerm({ dashboardId: 0, value: term });
  };

  handleOpenChartsClicked = (expiration, symbol) => {
    const { activeBroker, openActiveBrokerTabThunk, logger } = this.props;
    openActiveBrokerTabThunk();
    events.openBrokerChart.emit({ expiration, symbol, broker: activeBroker });
    logger.info("user.click.openChart", { broker: activeBroker, symbol, expiration });
  };

  handleOpenPosition = (symbol, side, expiration) => {
    const { activeBroker, openActiveBrokerTabThunk, logger } = this.props;
    openActiveBrokerTabThunk();
    events.openBrokerPosition.emit({ symbol, side, expiration, broker: activeBroker });
    logger.info("user.click.openPosition", { broker: activeBroker, symbol, expiration });
  };

  render() {
    const {
      signals,
      algorithms,
      isPro,
      freeAssets,
      currentTms,
      variant,
      activeSignalsType,
      presets,
      availableTypesList,
      activeBroker,
      availableBrokerIntegrationList,
    } = this.props;

    let activePresetName = availableTypesList?.find(({ id }) => id === activeSignalsType?.id)?.title;

    if (!activePresetName) {
      const preset = presets.find((preset) => preset.presetId === activeSignalsType?.id);
      if (preset) {
        activePresetName = preset.presetName;
      }
    }

    return (
      <View {...this.props} increment={this.state.increment} activePresetName={activePresetName} activeTerm={activeSignalsType?.term}>
        {signals &&
          signals.length > 0 &&
          signals.map((signal, index) => {
            const { timestamp, ttl, symbol } = signal;
            let showFull = isPro;
            if (freeAssets.indexOf(symbol.toLowerCase()) !== -1) showFull = true;
            if (currentTms / 1000 - timestamp < ttl)
              return (
                <Signal
                  key={index}
                  variant={variant}
                  data={signal}
                  algorithms={algorithms}
                  showFull={showFull}
                  currentTms={currentTms}
                  activeBroker={activeBroker}
                  availableBrokerIntegrationList={availableBrokerIntegrationList}
                  onOpenPosition={this.handleOpenPosition}
                  onOpenChartsClicked={this.handleOpenChartsClicked}
                  onSetSymbolClicked={this.handleSetSymbolClicked}
                />
              );

            return null;
          })}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    signals: getSignals(state),
    algorithms: getAlgorithms(state),
    freeAssets: getFreeAssets(state),
    isPro: isClientPRO(state),
    currentTms: getCurrentTms(state),
    variant: getSignalView(state),
    activeSignalsType: getActiveSignalsType(state),
    presets: getFiltersPresets(state),
    availableTypesList: getSignalTypesList(state),
    activeBroker: getActiveBroker(state),
    availableBrokerIntegrationList: getAvailableBrokerIntegrationList(state),
  }),
  { setSymbol, setTerm, setEnabled, newTab, openActiveBrokerTabThunk }
)(withLogger(SignalsList));
