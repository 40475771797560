import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSignalTypesList } from "../../../../modules/initial";
import { getActiveSignalsType, setActiveSignalsType } from "../../../../modules/client";
import { isClientPRO } from "../../../../modules/mainSettings";

import View from "./View";
import { getFiltersPresets } from "../../../../modules/filtersSettings";

class TypeSettings extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    isPro: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    availableTypesList: PropTypes.object.isRequired,
    activeSignalsType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      term: PropTypes.number,
    }).isRequired,
  };

  handleChangeSignalsType = ({ id, term, source }) => {
    this.props.setActiveSignalsType({ id, term, source });
    setTimeout(this.props.handleClick, 300);
  };

  render() {
    const {
      availableTypesList,
      isActive,
      activeSignalsType,
      isPro,
      presets,
    } = this.props;

    if (!activeSignalsType) return null;

    const unlockedTypes = [10, 20, 11, 21];

    const marketSignalTypesList = availableTypesList
      ?.filter(({ market_id }) => market_id === 0 || market_id === 1)
      .map((category) => {
        const { id, title, source } = category;
        return {
          id,
          name: title,
          source,
          unlocked: !isPro ? unlockedTypes.includes(parseInt(id)) : isPro,
        };
      });

    const otcSignalTypesList = availableTypesList
      ?.filter(({ market_id }) => market_id === 2)
      .map((category) => {
        const { id, title, source } = category;
        return {
          id,
          name: title,
          source,
          unlocked: isPro,
        };
      });

    const sortedPresets = presets.sort((a, b) => a.createdAt - b.createdAt);
    let marketPresets = [];
    let otcPresets = [];

    try {
      marketPresets = sortedPresets.filter(({ market_id }) => market_id === 0 || market_id === 1);
      otcPresets = sortedPresets.filter(({ market_id }) => market_id === 2);
    } catch (e) {
      console.error(e);
    }

    return (
      <View
        isActive={isActive}
        marketList={marketSignalTypesList}
        otcList={otcSignalTypesList ?? undefined}
        marketPresets={marketPresets}
        otcPresets={otcPresets ?? undefined}
        onClick={this.handleChangeSignalsType}
        activeItem={activeSignalsType}
      />
    );
  }
}

export default connect(
  (state) => ({
    availableTypesList: getSignalTypesList(state),
    activeSignalsType: getActiveSignalsType(state),
    presets: getFiltersPresets(state),
    isPro: isClientPRO(state),
  }),
  { setActiveSignalsType }
)(TypeSettings);
