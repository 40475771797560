import { getFiltersAssets, getFiltersPresets } from "../filtersSettings";
import { getActiveSignalsType } from "../client";
import { getAvailableSignalType } from "../initial";
import params from "../../config";

const TEST_SIGNALS_MARKET = 9;

export const getSignalsMarketAlg = (state) => {
  const { id } = getActiveSignalsType(state);
  return getAvailableSignalType(state, parseInt(id));
};

export const getSignals = (state) => {
  const activeSignalsType = getActiveSignalsType(state);

  if (!activeSignalsType) return [];

  const { id: typeId, term: signalsTerm, source } = activeSignalsType;
  let list = state.signals.list.find((signals) => signals.source === source)?.signals;
  if (!list) return [];

  const clientFilter = getAvailableSignalType(state, parseInt(typeId));

  // фильтруем по custom preset
  if (!clientFilter) {
    const preset = getFiltersPresets(state).find(({ presetId }) => presetId === typeId);

    if (!preset) return list;

    return list.filter(({ symbol, expiration, power, alg }) => {
      if (preset.selected.indexOf(symbol.toUpperCase()) < 0) return false;
      if (preset.power.indexOf(power) < 0) return false;
      if (preset.algorithm.indexOf(alg.toString()) < 0) return false;

      const termValue = expiration;
      if (preset.expiration.indexOf(termValue) < 0) return false;
      if (!signalsTerm) return true;

      return signalsTerm === termValue;
    });
  }

  // фильтруем по предустановленным presets из initial->available-signals-category
  return list.filter(({ expiration, market_id, alg_type, period }) => {
    let result;
    if (clientFilter?.market_id === TEST_SIGNALS_MARKET) {
      result = TEST_SIGNALS_MARKET === market_id;
    } else {
      result = clientFilter?.alg_type === alg_type && clientFilter?.market_id === market_id;
    }
    const termValue = params.appEnvType === "binary" ? expiration : period;
    return signalsTerm ? result && signalsTerm === termValue : result;
  });
};
